.menu-button {
  display: flex;
  width: 25px;
  align-items: center;
  justify-content: center;
}

.menu-button.menu-button[data-size='small'] {
  padding: 0;
}

.menu-button > [data-component='trailingAction'] {
  width: 25px;
}
